import styles from '../../_modals/InfoModal/InfoModal.module.scss';

const Templates = {
  delete: {
    style: styles.InfoModal__Icon_Delete,
    header: 'Удалить сотрудника?',
    buttonStyle: styles.InfoModal__Button_Delete,
    buttonText: 'Да, удалить',
    iconStyle: 'i-dp-delete',
    buttonIcon: 'i-dp-delete',
    question: 'Вы хотите удалить сотрудника {0}?',
  },

  block: {
    style: styles.InfoModal__Icon_Blocked,
    header: 'Заблокировать сотрудника?',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Да, заблокировать',
    iconStyle: 'i-dp-block',
    buttonIcon: 'i-dp-block',
    question: 'Вы хотите заблокировать сотрудника {0}?',
  },

  unblock: {
    style: styles.InfoModal__Icon_Blocked,
    header: 'Разблокировать сотрудника?',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Да, разблокировать',
    iconStyle: 'i-dp-done',
    buttonIcon: 'i-dp-done',
    question: 'Вы хотите разблокировать сотрудника {0}?',
  },

  added: {
    style: styles.InfoModal__Icon_Done,
    header: 'Запрос принят',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Закрыть',
    iconStyle: 'i-dp-done',
    buttonIcon: '',
    question:  `Будет обработано в течение 30 минут.\nДождитесь появления учетной записи в списке - не создавайте сотрудника повторно.\nУведомление с логином/паролем на почту больше не приходит.`,
  },

  edited: {
    style: styles.InfoModal__Icon_Done,
    header: 'Данные сохранены',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Закрыть',
    iconStyle: 'i-dp-done',
    buttonIcon: '',
    question:
      'Все изменения, которые были внесены по сотруднику {0}, добавлены в список',
  },
  deletedone: {
    style: styles.InfoModal__Icon_Done,
    header: 'Данные сотрудника удалены',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Закрыть',
    iconStyle: 'i-dp-done',
    buttonIcon: '',
    question: 'Данные сотрудника {0} удалены из списка',
  },
  blockdone: {
    style: styles.InfoModal__Icon_Done,
    header: 'Запрос принят',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Закрыть',
    iconStyle: 'i-dp-done',
    buttonIcon: '',
    question: 'Учетная запись будет заблокирована в течение 30 минут',
  },
  unblockdone: {
    style: styles.InfoModal__Icon_Done,
    header: 'Запрос принят',
    buttonStyle: styles.InfoModal__Button,
    buttonText: 'Закрыть',
    iconStyle: 'i-dp-done',
    buttonIcon: '',
    question: 'Учетная запись будет разблокирована в течение 30 минут',
  },
};

export default Templates;
